/* eslint-disable */
import { sentryInit } from './libs/sentry';
import { isSafariInit } from './libs/isSafari';
import { checkTranslations } from './utils/translations'

import './libs/client-ids';
import '@skillbox/form-autocomplete';
import '@skillbox/cookie-sync';
import './libs/user-sesson';
import './libs/utm-service';

import { customAnalyticsInit } from './libs/customAnalytics/index';
import { growthbookInit } from './libs/growthbook';
import { retailRocketInit } from './libs/retail-rocket';

import { formsInit } from './components/forms';
import { accordionInit } from './components/accordion';
import { spoilerInit } from './components/Spoiler';
import { gtmAnalyticHandlersInit } from './components/gtm-events';
import { slidersInit } from './components/defaultSlider';
import { tooltipsInit } from './components/tooltip';
import { buttonsHandlersInit } from './components/button-actions';
import { videoModalInit } from './components/UiVideoModal';
import { addBackButton } from './components/UiBackButton/UiBackButton';
import { abInit } from './libs/ab-testing';
import { videoObserverInit } from './utils/lazyload-video';

sentryInit();
abInit();
isSafariInit();
checkTranslations();
customAnalyticsInit();
growthbookInit();
retailRocketInit();
addBackButton();
formsInit();
accordionInit();
spoilerInit();
gtmAnalyticHandlersInit();
slidersInit();
tooltipsInit();
buttonsHandlersInit();
videoModalInit();
videoObserverInit();
/* eslint-enable */
